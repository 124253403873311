<template>
  <div>

    <form @submit.prevent="login">
      <h2>{{ $t('form.signin.title') }}</h2>
      <v-text-field
        type="email"
        :label="$t('form.signin.email.label')"
        v-model="email"
      />
      <v-text-field
        type="password"
        :label="$t('form.signin.password.label')"
        v-model="password"
      />
      <v-btn type="submit">{{ $t('btn.login') }}</v-btn>
    </form>
    <p class="mt-10" v-html="$t('hint.closed_beta_join')"></p>
  </div>
</template>
<script>

import {FirebaseAuth} from '../plugins/firebase';
import { mapGetters } from 'vuex'
import api from '../api'
export default {
  name: 'SignIn',
  created () {
  },
  mounted() {
  },
  computed: {
    ...mapGetters({
      isAuth: 'session/isAuth',
    }),
  },

  components: {
  },

  methods: {
    login() {
      const pwd = this.password
      this.password = ''
      // api.session.signup({
      //   email: this.email,
      //   password: pwd,
      //   displayName: 'Foo Bar',
      //   agreement: true,
      // }).then((res) => {
      //   console.log(res)
      // })
      FirebaseAuth
        .signInWithEmailAndPassword(this.email, pwd)
        .then(() => {
          this.$notifInfo("notif.successfully_signin")
        })
        .catch(err => {
          this.$notifError("notif.failed_signin", err)
        });
    }
  },

  data: () => ({
    email: '',
    password: '',
  }),
  watch: {
    isAuth(auth) {
      if (auth) {
        this.$router.push({name: 'Welcome'}).catch(() => {});
      }
    }
  }
};
</script>
